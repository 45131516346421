import * as actionTypes from './actionTypes';

const initialState = {
  building: false,
  project: false,
  style: false,
  package: false,
  product: false,
  activeStep: 1
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUILDING:
      return { ...state, building: action.building }
    case actionTypes.PROJECT:
      return { ...state, project: action.project }
    case actionTypes.STYLE:
      return { ...state, style: action.style }
    case actionTypes.PACKAGE:
      return { ...state, package: action.packages }
    case actionTypes.PRODUCT:
      return { ...state, product: action.product }
    case actionTypes.ACTIVESTEP:
      return { ...state, activeStep: action.activeStep }
    case actionTypes.RESET:
      return { 
        ...state, 
        building: false, 
        project: false, 
        style: false,
        package: false,
        product: false,
        activeStep: false
      }
    default:
      return state
  }
}
